const findMainModuleFile = (resources, mainFileTypes, data) => {
  for (let resource of resources) {
    const nameParts = resource.fileName?.split(".");
    const fileType = nameParts[nameParts.length - 1].toLowerCase();
    if (mainFileTypes.includes(fileType)) {
      data["notFound"] = false;
      data["resource"] = resource;
      data["fileType"] = fileType;
      break;
    }
  }
  return data;
};

export function fixname(name) {
  const charArrays = ["?", "+", "/"];
  charArrays.forEach((character) => {
    name = name.replaceAll(character, "");
  });
  return name;
}

//convert html, used for url link of each card in different language
export function decodeHTMLEntities(text) {
  var textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}

export function fixUrl(url) {
  if (!url) return "";
  url = url.split(" ").join("%20");
  if (
    url.startsWith("https://xcell-cms-files.s3.amazonaws.com") ||
    url.startsWith("https://xcell-cms-files.s3.ap-south-1.amazonaws.com")
  ) {
    return url;
  } else {
    return "https://xcell-cms-files.s3.amazonaws.com" + url;
  }
}

//Function to fix the thumbnail url
//Function to fix the file url based on the content type
export const fixFileUrl = (fileUrl, fileName) => {
  if (!fileUrl) {
    return false;
  }

  if (!fileUrl.startsWith("https://") && fileUrl) {
    fileUrl = fileUrl.split(" ").join("%20");
    fileUrl = "https://xcell-cms-files.s3.amazonaws.com" + fileUrl;
  }

  if (
    fileType(fileName) === "PowerPoint" ||
    fileType(fileName) === "WordDocument"
  ) {
    return "https://view.officeapps.live.com/op/embed.aspx?src=" + fileUrl;
    // return "https://docs.google.com/gview?url=" + fileUrl;
  } else if (fileType(fileName) === "Document") {
    return `${fileUrl}#toolbar=0`;
  } else {
    return fileUrl;
  }
};

//Function to return the file type
export const fileType = (type) => {
  if (type === "png" || type === "jpg" || type === "jpeg" || type === "gif") {
    return "Image";
  } else if (type === "pdf") {
    return "PdfDocument";
  } else if (type === "txt") {
    return "Document";
  } else if (
    type === "mp4" ||
    type === "mkv" ||
    type === "webm" ||
    type === "m4v"
  ) {
    return "Video";
  } else if (type === "swf") {
    return "swfVideo";
  } else if (type === "ppt" || type === "pptx") {
    return "PowerPoint";
  } else if (type === "html") {
    return "Activity";
  } else if (type === "mp3") {
    return "Audio";
  } else if (type === "docx") {
    return "WordDocument";
  } else if (type === "doc") {
    return "WordDocument";
  } else {
    return "Unsupported";
  }
};

//Function to decide the action to be executed based on the file type
export const actionType = (name) => {
  const type = fileType(name);
  if (type === "Video") {
    return "watch";
  } else if (type === "Image") {
    return "show";
  } else if (type === "PdfDocument") {
    return "pdfview";
  } else if (
    type === "Document" ||
    type === "PowerPoint" ||
    type === "WordDocument"
  ) {
    return "view";
  } else if (type === "swfVideo") {
    return "watchSWF";
  } else if (type === "Audio") {
    return "listen";
  }
};

//Function to compare the thumbnail and video
export const compare = (file) => {
  if (fileType(file) === "Image") {
    return 1;
  } else {
    return 0;
  }
};

function getFirstHTMLPage(resources) {
  const getLength = (file) => file?.fileUrl && file.fileUrl.split("/").length;
  const otherPages = resources
    .filter((resource) => {
      if (!resource?.fileUrl) return false;
      return resource.fileUrl.endsWith("html");
    })
    .sort((a, b) => getLength(a) - getLength(b));
  return otherPages[0];
}

export const getModuleType = (resources) => {
  var result = { notFound: true };
  const modulesTypes = [
    ["html"],
    ["mp4", "mkv", "m4v"],
    ["pptx", "ppt", "docx", "pdf"],
    ["mp3"],
    ["png", "jpg", "jpeg"],
  ];
  var move = 0,
    lastStep = modulesTypes.length - 1;
  while (result.notFound) {
    result = findMainModuleFile(resources, modulesTypes[move], result);
    if (move === lastStep) break;
    move += 1;
  }

  //If many htmls then prioritize html file
  if (result.fileType === "html") {
    result.resource = getFirstHTMLPage(resources);
  }

  return result;
};

export function groupModulesByCategory(modules) {
  // Create an object to hold the grouped modules
  const groupedModules = {
    Activities: [],
    Assignments: [],
    "E-Content": [],
    "Home work": [],
    "Questions Papers": [],
    "Revision Note": [],
    Worksheets: [],
    Module: [],
  };

  // Iterate over the modules array
  modules.forEach((module) => {
    const category = module.category;
    // Add the current module to the category array
    groupedModules[category].push(module);
  });

  // Convert the groupedModules object into an array of {categoryName, modules} objects
  return Object.keys(groupedModules).map((categoryName) => ({
    categoryName,
    modules: groupedModules[categoryName],
  }));
}

export async function downloadFile(url, filename) {
  const response = await fetch(url);

  // Check if the response is okay (status in the range 200-299)
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  // Create a blob from the response data
  const blob = await response.blob();

  // Create a URL for the blob
  const blobUrl = URL.createObjectURL(blob);

  // Create a new anchor element
  const a = document.createElement("a");
  a.href = blobUrl;
  a.download = filename;

  // Append the anchor to the body
  document.body.appendChild(a);

  // Trigger a click event on the anchor
  a.click();

  // Remove the anchor from the document
  document.body.removeChild(a);

  // Revoke the object URL to free up memory
  URL.revokeObjectURL(blobUrl);
}
