import React from "react";
import TableAnalytics from './Table';

export default function TeacherUsage () {
  document.title = "2xcell.in";
  return (
    <>
      <section className="my-5">
        <div className="container">
          <h1>Your Usage</h1>
          <div className="mt-2">
            <TableAnalytics />
          </div>
        </div>
      </section>
    </>
  );
};