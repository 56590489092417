import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AssignmentTurnedInOutlinedIcon from "@material-ui/icons/AssignmentTurnedInOutlined";
import CollectionsBookmarkOutlinedIcon from "@material-ui/icons/CollectionsBookmarkOutlined";
import InsertChartOutlinedIcon from "@material-ui/icons/InsertChartOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { NavLink } from "react-router-dom";
import AssignmentIcon from "@material-ui/icons/Assignment";
import DashboardIcon from "../../images/Sidebar/Dashboard.svg";
import ResourcesIcon from "../../images/Sidebar/Resources.svg";
import MyResourcesIcon from "../../images/Sidebar/MyResources.svg";
import MyAssessmentIcon from "../../images/Sidebar/MyAssessment.svg";
import LessonPlanIcon from "../LessonPlan/icons/lessonplanN.svg";
import MyClasses from "../../images/Sidebar/My Classes.svg";
import TaskListIcon from "../../images/Sidebar/TaskList.svg";
import AnalyticsIcon from "../../images/Sidebar/Analytics.svg";
import SettingsIcon from "../../images/Sidebar/Settings.svg";
import assessmentImg from "../../images/assessment.svg";
import FileSvg from "../LessonPlan/icons/File.svg";
import { GlobalContext } from "../../context/GlobalState";
import { ReactComponent as AccountIcon } from "../../images/AnnouncementUserLogo.svg";
import "./Sidebar.css";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import { useContext } from "react";
import SettingsLogoutIcon from "@material-ui/icons/Settings";
const useStyles = makeStyles({
  list: {
    width: 280,
  },
  fullList: {
    width: "auto",
  },
  sidebarLink: {
    background:
      "linear-gradient(90deg, #CEF6F2 0%, rgba(206, 246, 242, 0) 100%)",
    "&:hover": {
      background:
        "linear-gradient(90deg, #CEF6F2 0%, rgba(206, 246, 242, 0) 100%)",
    },
  },
});

const Sidebar = ({ slider, toggleDrawer }) => {
  const classes = useStyles();

  const [lang, setLang] = useState(window.localStorage.getItem("language"));

  const setLanguage = (lang) => {
    window.localStorage.setItem("language", lang);
    if (lang === "english") {
      document
        .getElementById("english_button")
        .classList.add("selected_language");
      document
        .getElementById("hindi_button")
        .classList.remove("selected_language");
      setLang("english");
    } else {
      document
        .getElementById("hindi_button")
        .classList.add("selected_language");
      document
        .getElementById("english_button")
        .classList.remove("selected_language");
      setLang("hindi");
    }
    window.location.reload();
  };
  const details = {
    marginTop: "1rem",
    width: "fit-content",
    "@media screen and (max-width:472x)": {
      display: "flex !important",
    },
  };
  const signOut = async () => {
    const token = localStorage.getItem("authToken");
    await fetch(`${process.env.REACT_APP_ORIGIN}/api/v1/auth/logout`, {
      headers: {
        token: token,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("sectionIdTask");
        window.location.href = `${process.env.REACT_APP_REDIRECT}?logout=true`;
      });
  };
  const toggleMenu = () => {
    if (
      document.querySelector(".dropdown-menu-mobile").style.display === "none"
    ) {
      document.querySelector(".dropdown-menu-mobile").style.display = "block";
      document.querySelector(".dropdown-menu-mobile").style.marginLeft = "10px";
    } else {
      document.querySelector(".dropdown-menu-mobile").style.display = "none";
    }
    console.log("7", document.querySelector(".dropdown-menu-mobile"));
  };
  const isSmallScreen = useMediaQuery({
    query: "(max-width:450px)",
  });
  const { userDetails, setUserDetails } = useContext(GlobalContext);
  console.log("userDetails", userDetails);
  const profileImageUrl = window.localStorage.getItem("profileImageUrl");
  //translate button
  const list = () => (
    <div className={clsx(classes.list)}>
      <div className="row" style={details}>
        <div className="profileSidebar" style={{ marginLeft: "5px" }}>
          <div className="teacherDetails style90">
            {false ? (
              <img
                src={userDetails?.profileImageUrl}
                width="50px"
                id="navbarDropdown"
                className="rounded-circle"
                role="button"
                data-bs-toggle="dropdown-menu-mobile"
                aria-expanded="false"
                href="#navbarDropdown"
                alt=""
              />
            ) : (
              <AccountIcon
                id="navbarDropdown"
                role="button"
                className="userImage"
                href="#navbarDropdown"
                aria-expanded="false"
              />
            )}
          </div>
          <div className="sidebarProfiletext">
            <div
              className="col-sm-8"
              style={{ marginTop: "20px", marginLeft: "5px" }}
            >
              <h4
                style={{
                  fontSize: "15px",
                  fontWeight: "600",
                  marginBlockEnd: "0px",
                  wordBreak: "break-word",
                  width: "70%",
                }}
              >
                {userDetails.name}
              </h4>
              <p style={{ fontSize: "10px" }}>{userDetails.schoolId.name}</p>
            </div>
            <NavLink to="#" onClick={signOut}>
              <span style={{ whiteSpace: "nowrap" }}>Logout</span>
            </NavLink>
            <NavLink to="/setting">
              <SettingsLogoutIcon
                className="sidebar_settings"
                onClick={toggleDrawer("Settings")}
              />
            </NavLink>
          </div>
        </div>
      </div>
      <List>
        <ListItem
          button
          component={NavLink}
          to="/dashboard"
          activeClassName={classes.sidebarLink}
          exact={true}
          onClick={toggleDrawer("Dashboard")}
        >
          <ListItemIcon>
            <img src={DashboardIcon} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" className="labelText" />
        </ListItem>

        <ListItem
          button
          component={NavLink}
          to="/myresources"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer("Resources")}
        >
          <ListItemIcon>
            <img src={ResourcesIcon} />
          </ListItemIcon>
          <ListItemText primary="Resources" className="labelText" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to="/lessonplans"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer("Resources")}
        >
          <ListItemIcon>
            <img width={"20px"} src={LessonPlanIcon} />
          </ListItemIcon>
          <ListItemText primary="Lesson Plans" className="labelText" />
        </ListItem>

        {/* <ListItem
          button
          component={NavLink}
          to="/myresources"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer()}
        >
          <ListItemIcon>
            <img src={MyResourcesIcon} />
          </ListItemIcon>
          <ListItemText primary="My Resources" className="labelText" />
        </ListItem> */}
        {/* 
        <ListItem
          button
          component={NavLink}
          to="/myassessment"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer()}
        >
          <ListItemIcon>
            <img src={MyAssessmentIcon} />
          </ListItemIcon>
          <ListItemText primary="My Assessment" className="labelText" />
        </ListItem> */}

        <ListItem
          button
          component={NavLink}
          to="/myclasses"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer("Grades")}
        >
          <ListItemIcon>
            <img src={MyClasses} />
          </ListItemIcon>
          <ListItemText primary="Grades" className="labelText" />
        </ListItem>

        <ListItem
          button
          component={NavLink}
          to="/tasklist"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer("Tasklist")}
        >
          <ListItemIcon>
            <img src={TaskListIcon} />
          </ListItemIcon>
          <ListItemText primary="Task List" className="labelText" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to="/analytics"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer("Analytics")}
        >
          <ListItemIcon>
            <img src={AnalyticsIcon} />
          </ListItemIcon>
          <ListItemText primary="Analytics" className="labelText" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to="/usage"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer("Usage")}
        >
          <ListItemIcon>
            <img src={AnalyticsIcon} />
          </ListItemIcon>
          <ListItemText primary="Usage Report" className="labelText" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to="/report"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer("Report")}
        >
          <ListItemIcon>
            <img src={assessmentImg} />
          </ListItemIcon>
          <ListItemText primary="Assessment Report" className="labelText" />
        </ListItem>
        <ListItem
          button
          component={NavLink}
          to="/setting"
          activeClassName={classes.sidebarLink}
          onClick={toggleDrawer("Settings")}
        >
          <ListItemIcon>
            <img src={SettingsIcon} />
          </ListItemIcon>
          <ListItemText primary="Settings" className="labelText" />
        </ListItem>
      </List>
      {/**Switch Lang*/}

      {/**lang switch*/}
    </div>
  );

  return (
    <>
      <SwipeableDrawer
        anchor="left"
        open={slider}
        onClose={toggleDrawer()}
        onOpen={toggleDrawer()}
      >
        {list()}
      </SwipeableDrawer>
    </>
  );
};

export default Sidebar;
