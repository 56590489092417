import "../OneWord/oneWord.css";
import { useEffect, useState } from "react";
import EditCard from "../../EditCard/EditCard";
import QuestionChecker from "./QuestionChecker";

import { htmlDecode } from "../../../utils/parse";
import { QuestionImg } from "./Component";

export default function TestOneWord({
  isPraticeSession,
  isChecked,
  question,
  index,
  deleteQuestion,
  setQuestId,
  setSelQuest,
  isMarks,
  page,
}) {
  const [edit, setEdit] = useState(false);
  const openEdit = () => setEdit(true);
  const closeEdit = () => setEdit(false);

  const [input, setInput] = useState({ blankId: "op1", answer: "" });

  function handleInput(e) {
    setInput({
      blankId: e.target.name,
      answer: e.target.value,
    });
  }

  useEffect(() => {
    if (window.MathJax) window.MathJax.typesetPromise();
  }, [question.question]);

  return (
    <>
      {edit && (
        <EditCard
          edit={edit}
          closeEdit={closeEdit}
          quesType="oneword"
          question={question}
          setQuestId={setQuestId}
          setSelQuest={setSelQuest}
          isMarks={isMarks}
        />
      )}
      <div
        className={
          isChecked
            ? "container my-4 onlineQuestionCard"
            : "container my-4 questionCard"
        }
        style={{ display: edit ? "none" : "block" }}
      >
        <div className="superhead">
          <div
            className={
              isPraticeSession ? "remark ps_remark" : "remark ot_remark"
            }
          >
            Answer in one word
          </div>
          <div
            className={
              isPraticeSession
                ? "one__head ps_mcq_head"
                : "one__head ot_mcq_head"
            }
          >
            <QuestionChecker
              isStaff={question.onModel === "staff"}
              type={question.questionType}
              options={question.options}
              correctAnswers={question.correctAnswers[question.questionType]}
              answers={input}
              reset={() => setInput({ blankId: "op1", answer: "" })}
              editMode={edit}
              openEdit={openEdit}
              delete={() => deleteQuestion(question._id)}
              isPraticeSession={isPraticeSession}
              staff={{
                name: question.creatorId?.name,
                schoolName: question.schoolId?.name,
              }}
            >
              <div className="oneCard">
                <div
                  className="oneCard__left"
                  style={
                    isPraticeSession ? { marginTop: "16px" } : { marginTop: "" }
                  }
                >
                  <div style={{ minWidth: "fit-content" }}>
                    Q{5 * (page - 1) + index}.&nbsp;
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: htmlDecode(question.question),
                    }}
                  ></div>
                </div>
                <div className="oneCard__right">
                  <span
                    className={
                      isPraticeSession
                        ? "difficulty_status bg_ps"
                        : "difficulty_status bg_ot"
                    }
                  >
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2H12C12.5523 2 13 1.55228 13 1C13 0.447715 12.5523 0 12 0H1Z"
                        fill="#00C550"
                      />
                      <path
                        d="M1 4C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6H6C6.55228 6 7 5.55228 7 5C7 4.44772 6.55228 4 6 4H1Z"
                        fill="#00C550"
                      />
                      <path
                        d="M1 8C0.447715 8 0 8.44772 0 9C0 9.55229 0.447715 10 1 10H5C5.55228 10 6 9.55229 6 9C6 8.44772 5.55228 8 5 8H1Z"
                        fill="#00C550"
                      />
                      <path
                        d="M11 13C11 13.5523 11.4477 14 12 14C12.5523 14 13 13.5523 13 13L13 7.41421L14.2929 8.70711C14.6834 9.09763 15.3166 9.09763 15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289L8.29289 7.29289C7.90237 7.68342 7.90237 8.31658 8.29289 8.70711C8.68342 9.09763 9.31658 9.09763 9.70711 8.70711L11 7.41421L11 13Z"
                        fill="#00C550"
                      />
                    </svg>
                    {question.difficulty}
                  </span>

                  <span
                    className={
                      isPraticeSession
                        ? "congnitive_status bg_ps"
                        : "congnitive_status bg_ot"
                    }
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 1C9 0.447715 8.55229 0 8 0C7.44771 0 7 0.447715 7 1V2C7 2.55228 7.44771 3 8 3C8.55229 3 9 2.55228 9 2V1Z"
                        fill="#00C550"
                      />
                      <path
                        d="M13.6568 3.75731C14.0473 3.36678 14.0473 2.73362 13.6568 2.34309C13.2663 1.95257 12.6331 1.95257 12.2426 2.34309L11.5355 3.0502C11.145 3.44072 11.145 4.07389 11.5355 4.46441C11.926 4.85494 12.5592 4.85494 12.9497 4.46441L13.6568 3.75731Z"
                        fill="#00C550"
                      />
                      <path
                        d="M16 8C16 8.55229 15.5523 9 15 9H14C13.4477 9 13 8.55228 13 8C13 7.44771 13.4477 7 14 7H15C15.5523 7 16 7.44771 16 8Z"
                        fill="#00C550"
                      />
                      <path
                        d="M3.05019 4.46443C3.44071 4.85496 4.07388 4.85496 4.4644 4.46443C4.85493 4.07391 4.85493 3.44074 4.4644 3.05022L3.7573 2.34311C3.36677 1.95259 2.73361 1.95259 2.34308 2.34311C1.95256 2.73363 1.95256 3.3668 2.34308 3.75732L3.05019 4.46443Z"
                        fill="#00C550"
                      />
                      <path
                        d="M3 8C3 8.55229 2.55228 9 2 9H1C0.447715 9 -1.19209e-07 8.55228 0 8C0 7.44771 0.447715 7 1 7H2C2.55228 7 3 7.44771 3 8Z"
                        fill="#00C550"
                      />
                      <path
                        d="M6 14V13H10V14C10 15.1046 9.10457 16 8 16C6.89543 16 6 15.1046 6 14Z"
                        fill="#00C550"
                      />
                      <path
                        d="M10.0009 12C10.0155 11.6597 10.2076 11.3537 10.4768 11.1411C11.4046 10.4086 12 9.27384 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 9.27384 4.59545 10.4086 5.52319 11.1411C5.79241 11.3537 5.98451 11.6597 5.99911 12H10.0009Z"
                        fill="#00C550"
                      />
                    </svg>
                    {question.cognitive}
                  </span>
                </div>
              </div>

              <div
                className="oneWord"
                style={
                  question.images?.questionImg
                    ? { justifyContent: "space-around" }
                    : { justifyContent: "unset" }
                }
              >
                {question.images?.questionImg ? (
                  /*<div
                    className="image-wrapper"
                  >
                    <img
                      src={question.images?.questionImg}
                    />
                  </div>*/
                  <QuestionImg image={question.images?.questionImg} />
                ) : null}
                <input
                  className={isPraticeSession ? "ps-op1" : "ot-op1"}
                  style={{ marginRight: "20px" }}
                  name={"op1"}
                  value={input.answer}
                  onChange={handleInput}
                />
              </div>
            </QuestionChecker>
          </div>
        </div>
      </div>
    </>
  );
}
